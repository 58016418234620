import routerOptions0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_floating-vue@5.2.2_qrcode@1.5.3_rollup@2.79.1_sass@1.74.1_ty_ezukaqqlazirmkxvsg2mqowxki/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/buildhome/repo/app/router.options.js";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}