
export default { 
  scrollBehavior(to, from, savedPosition) {

    // ignore if to and from is both on project

    if(to.name === 'project' && from.name === 'project') {
      return savedPosition
    }else {

      if (savedPosition) {
        return savedPosition
      } else {
        return { 
          top: 0,
          behavior: 'smooth',
        }
      }

    }

  }
}