import { default as accountn3gTymoXiRMeta } from "/opt/buildhome/repo/pages/app/account.vue?macro=true";
import { default as calendarb4prjGumroMeta } from "/opt/buildhome/repo/pages/app/calendar.vue?macro=true";
import { default as claim6EGFFj9ZCCMeta } from "/opt/buildhome/repo/pages/app/claim.vue?macro=true";
import { default as indexIbNDbq129sMeta } from "/opt/buildhome/repo/pages/app/index.vue?macro=true";
import { default as inoH3cH0uYVClMeta } from "/opt/buildhome/repo/pages/app/ino.vue?macro=true";
import { default as otc0bBn3qCnTKMeta } from "/opt/buildhome/repo/pages/app/otc.vue?macro=true";
import { default as policy0sI0knZrAkMeta } from "/opt/buildhome/repo/pages/app/policy.vue?macro=true";
import { default as private_45sale_45passXFXFzGrclXMeta } from "/opt/buildhome/repo/pages/app/private-sale-pass.vue?macro=true";
import { default as _91project_id_93FVP4p4WXcJMeta } from "/opt/buildhome/repo/pages/app/private/[project_id].vue?macro=true";
import { default as _91project_id_93Z6FjfIOfkbMeta } from "/opt/buildhome/repo/pages/app/project/[project_id].vue?macro=true";
import { default as stakenCv5hC8GUjMeta } from "/opt/buildhome/repo/pages/app/stake.vue?macro=true";
import { default as tg_45link5ZNy0kZt7qMeta } from "/opt/buildhome/repo/pages/app/tg-link.vue?macro=true";
import { default as giftWO6Vqj3a1mMeta } from "/opt/buildhome/repo/pages/gift.vue?macro=true";
import { default as hall_45of_45fame0QEA535VDBMeta } from "/opt/buildhome/repo/pages/hall-of-fame.vue?macro=true";
import { default as index_copyhvKeYbnBJjMeta } from "/opt/buildhome/repo/pages/index_copy.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as privacy69l1Gm1VSzMeta } from "/opt/buildhome/repo/pages/privacy.vue?macro=true";
export default [
  {
    name: accountn3gTymoXiRMeta?.name ?? "app-account",
    path: accountn3gTymoXiRMeta?.path ?? "/app/account",
    meta: accountn3gTymoXiRMeta || {},
    alias: accountn3gTymoXiRMeta?.alias || [],
    redirect: accountn3gTymoXiRMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/app/account.vue").then(m => m.default || m)
  },
  {
    name: calendarb4prjGumroMeta?.name ?? "app-calendar",
    path: calendarb4prjGumroMeta?.path ?? "/app/calendar",
    meta: calendarb4prjGumroMeta || {},
    alias: calendarb4prjGumroMeta?.alias || [],
    redirect: calendarb4prjGumroMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/app/calendar.vue").then(m => m.default || m)
  },
  {
    name: claim6EGFFj9ZCCMeta?.name ?? "app-claim",
    path: claim6EGFFj9ZCCMeta?.path ?? "/app/claim",
    meta: claim6EGFFj9ZCCMeta || {},
    alias: claim6EGFFj9ZCCMeta?.alias || [],
    redirect: claim6EGFFj9ZCCMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/app/claim.vue").then(m => m.default || m)
  },
  {
    name: indexIbNDbq129sMeta?.name ?? "app",
    path: indexIbNDbq129sMeta?.path ?? "/app",
    meta: indexIbNDbq129sMeta || {},
    alias: indexIbNDbq129sMeta?.alias || [],
    redirect: indexIbNDbq129sMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: inoH3cH0uYVClMeta?.name ?? "app-ino",
    path: inoH3cH0uYVClMeta?.path ?? "/app/ino",
    meta: inoH3cH0uYVClMeta || {},
    alias: inoH3cH0uYVClMeta?.alias || [],
    redirect: inoH3cH0uYVClMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/app/ino.vue").then(m => m.default || m)
  },
  {
    name: otc0bBn3qCnTKMeta?.name ?? "app-otc",
    path: otc0bBn3qCnTKMeta?.path ?? "/app/otc",
    meta: otc0bBn3qCnTKMeta || {},
    alias: otc0bBn3qCnTKMeta?.alias || [],
    redirect: otc0bBn3qCnTKMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/app/otc.vue").then(m => m.default || m)
  },
  {
    name: policy0sI0knZrAkMeta?.name ?? "app-policy",
    path: policy0sI0knZrAkMeta?.path ?? "/app/policy",
    meta: policy0sI0knZrAkMeta || {},
    alias: policy0sI0knZrAkMeta?.alias || [],
    redirect: policy0sI0knZrAkMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/app/policy.vue").then(m => m.default || m)
  },
  {
    name: private_45sale_45passXFXFzGrclXMeta?.name ?? "app-private-sale-pass",
    path: private_45sale_45passXFXFzGrclXMeta?.path ?? "/app/private-sale-pass",
    meta: private_45sale_45passXFXFzGrclXMeta || {},
    alias: private_45sale_45passXFXFzGrclXMeta?.alias || [],
    redirect: private_45sale_45passXFXFzGrclXMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/app/private-sale-pass.vue").then(m => m.default || m)
  },
  {
    name: _91project_id_93FVP4p4WXcJMeta?.name ?? "app-private-project_id",
    path: _91project_id_93FVP4p4WXcJMeta?.path ?? "/app/private/:project_id()",
    meta: _91project_id_93FVP4p4WXcJMeta || {},
    alias: _91project_id_93FVP4p4WXcJMeta?.alias || [],
    redirect: _91project_id_93FVP4p4WXcJMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/app/private/[project_id].vue").then(m => m.default || m)
  },
  {
    name: _91project_id_93Z6FjfIOfkbMeta?.name ?? "app-project-project_id",
    path: _91project_id_93Z6FjfIOfkbMeta?.path ?? "/app/project/:project_id()",
    meta: _91project_id_93Z6FjfIOfkbMeta || {},
    alias: _91project_id_93Z6FjfIOfkbMeta?.alias || [],
    redirect: _91project_id_93Z6FjfIOfkbMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/app/project/[project_id].vue").then(m => m.default || m)
  },
  {
    name: stakenCv5hC8GUjMeta?.name ?? "app-stake",
    path: stakenCv5hC8GUjMeta?.path ?? "/app/stake",
    meta: stakenCv5hC8GUjMeta || {},
    alias: stakenCv5hC8GUjMeta?.alias || [],
    redirect: stakenCv5hC8GUjMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/app/stake.vue").then(m => m.default || m)
  },
  {
    name: tg_45link5ZNy0kZt7qMeta?.name ?? "app-tg-link",
    path: tg_45link5ZNy0kZt7qMeta?.path ?? "/app/tg-link",
    meta: tg_45link5ZNy0kZt7qMeta || {},
    alias: tg_45link5ZNy0kZt7qMeta?.alias || [],
    redirect: tg_45link5ZNy0kZt7qMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/app/tg-link.vue").then(m => m.default || m)
  },
  {
    name: giftWO6Vqj3a1mMeta?.name ?? "gift",
    path: giftWO6Vqj3a1mMeta?.path ?? "/gift",
    meta: giftWO6Vqj3a1mMeta || {},
    alias: giftWO6Vqj3a1mMeta?.alias || [],
    redirect: giftWO6Vqj3a1mMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/gift.vue").then(m => m.default || m)
  },
  {
    name: hall_45of_45fame0QEA535VDBMeta?.name ?? "hall-of-fame",
    path: hall_45of_45fame0QEA535VDBMeta?.path ?? "/hall-of-fame",
    meta: hall_45of_45fame0QEA535VDBMeta || {},
    alias: hall_45of_45fame0QEA535VDBMeta?.alias || [],
    redirect: hall_45of_45fame0QEA535VDBMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/hall-of-fame.vue").then(m => m.default || m)
  },
  {
    name: index_copyhvKeYbnBJjMeta?.name ?? "index_copy",
    path: index_copyhvKeYbnBJjMeta?.path ?? "/index_copy",
    meta: index_copyhvKeYbnBJjMeta || {},
    alias: index_copyhvKeYbnBJjMeta?.alias || [],
    redirect: index_copyhvKeYbnBJjMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index_copy.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacy69l1Gm1VSzMeta?.name ?? "privacy",
    path: privacy69l1Gm1VSzMeta?.path ?? "/privacy",
    meta: privacy69l1Gm1VSzMeta || {},
    alias: privacy69l1Gm1VSzMeta?.alias || [],
    redirect: privacy69l1Gm1VSzMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/privacy.vue").then(m => m.default || m)
  }
]