import { queryNoCache } from "~/apollo";
import { gql } from "@apollo/client/core";

// import { useUserStore } from "~/stores/user";

const projectQuery = `
        query getProjectsWithPagination {
        featured: getProjectsWithPagination(
          after: 0
          status: FEATURED
          limit: 10
          max: 0
        ) {
          max
          edges {
            cursor
            node {
              project_id
              project_name
              project_logo
              project_banner
              project_description
              project_ath
              project_tier_allocation { tier1_min tier2_min tier3_min tier1_max tier2_max tier3_max currency }
              is_ushi_private
            }
          }
        }
        upcoming: getProjectsWithPagination(
          after: 0
          status: UPCOMING
          limit: 6
          max: 0
        ) {
          max
          edges {
            cursor
            node {
              project_id
              project_name
              project_logo
              project_banner
              project_description
              project_tier_allocation { tier1_min tier2_min tier3_min tier1_max tier2_max tier3_max currency }
              is_ushi_private
            }
          }
        }
        active: getProjectsWithPagination(after: 0, status: ACTIVE, limit: 6, max: 0) {
          max
          edges {
            cursor
            node {
              project_id
              project_name
              project_logo
              project_banner
              project_description
              project_tier_allocation { tier1_min tier2_min tier3_min tier1_max tier2_max tier3_max currency }
              is_ushi_private
            }
          }
        }
        completed: getProjectsWithPagination(
          after: 0
          status: COMPLETED
          limit: 100
          max: 0
          before: 27
        ) {
          max
          edges {
            cursor
            node {
              project_id
              project_name
              project_logo
              project_description
              project_ath
              project_contributed
              project_tier_allocation { tier1_min tier2_min tier3_min tier1_max tier2_max tier3_max currency }
              is_ushi_private
            }
          }
        }
        recent: getRecentProjects {
            max
            edges {
              cursor
              node {
                project_id
                project_name
                project_logo
                project_banner
                project_description
                project_ath
                project_contributed
                project_tier_allocation { tier1_min tier2_min tier3_min tier1_max tier2_max tier3_max currency }
                is_ushi_private
              }
            }
          }
      }
        `;



class HomeManager {

    authState = false
    update = false

    project = {}
    announcements = []

    posts = []

    userStore = useUserStore();


    // Constructor
    // required callbacks
    // authState = function to get the auth state
    // update = function to output the update
    constructor(callbacks) {
        this.update = callbacks.update
        this.authState = callbacks.authState
    }

    getProjects = () => {
        return new Promise( async (resolve, reject) => {
            let authenticated = this.authState()
            let graphQLResponse;

            const { $accessToken } = useNuxtApp()

            

              


            if ( $accessToken.value) {
                try {

                    graphQLResponse = await queryNoCache(gql(projectQuery));
                    // console.log(projects.value);

                    // return { projectCategories, loadMoreAvailable };
                } catch (error) {
                    // console.error("Error fetching projects:", error);
                    throw error;
                }
            } 
            
            // Project is not public yet
            else {
                graphQLResponse = await queryNoCache(gql(projectQuery), null, true);
            }

            console.log(graphQLResponse, "GQLRES")

            if (graphQLResponse) {
                const projectCategories = {
                    featured: { projects: [], more: false, cursor: 0 },
                    upcoming: { projects: [], more: false, cursor: 0 },
                    active: { projects: [], more: false, cursor: 0 },
                    completed: { projects: [], more: false, cursor: 0 },
                    recent: { projects: [], more: false, cursor: 0 },
                };
                // Group projects into categories
                for (const category in projectCategories) {
                    if (graphQLResponse[category]) {
                        projectCategories[category].projects = graphQLResponse[category].edges.map((edge) => edge.node);
                        projectCategories[category].more = graphQLResponse[category].max > projectCategories[category].projects?.length;
                        projectCategories[category].cursor = graphQLResponse[category].edges.slice(-1)[0]?.cursor || 0;
                        projectCategories[category].max = graphQLResponse[category].max;
                    }
                }

                this.project = projectCategories
                // console.log('projectCategories', projectCategories)
            }
            resolve()
        })
    }

    getAnn = () => {
        return new Promise( async (resolve, reject) => {
            try {
                const response = await $fetch("https://ukfztwboozjkcxaclima.supabase.co/rest/v1/twitter_annoucement?order=sequence.desc&show=eq.true", {
                  method: "GET",
                  headers: {
                    apikey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrZnp0d2Jvb3pqa2N4YWNsaW1hIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODM2MDg0ODcsImV4cCI6MTk5OTE4NDQ4N30.15MQeuuQKzj-i4BgcMIDY30_pcx4qqw0feulCQREwIE",
                    Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrZnp0d2Jvb3pqa2N4YWNsaW1hIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODM2MDg0ODcsImV4cCI6MTk5OTE4NDQ4N30.15MQeuuQKzj-i4BgcMIDY30_pcx4qqw0feulCQREwIE",
                  },
                });
            
                const result = response;
                this.announcements = []
                for (let res of result) {
                  this.announcements.push({
                    title: res.title,
                    banner: res.banner,
                    type: res.type,
                    config: {
                      dataTweetUrl: res.data_tweet_url,
                    },
                  });
                }

                resolve()


              } catch (error) {
                console.log(error);
              }
        })
    }

    render = async () => {
        await Promise.all([this.getAnn()])
        
        this.posts = []

        let _annoucementPosts = []

        for (let ann of this.announcements) {
            _annoucementPosts.push({
                title: ann.title,
                image: ann.banner,
                type: "announcement",
            })
        }

        // this.posts.push({
        //     label: "Announcements",
        //     key: "announcement",
        //     posts: _annoucementPosts
        // })

        // /// loop featured projects, completed

        // let keys = ["featured", "completed"]

        // for(let _key of keys) {
        //     let _posts = []

        //     for (let project of this.project[_key].projects) {

        //         _posts.push({
        //             title: project.project_name,
        //             image: project.project_banner,
        //             description: project.project_description,
        //             allocation: project.project_allocation,
        //             ath: project.project_ath,
        //             tiers: project.project_tier_allocation,
        //             type: "project",
        //         })
        //     }

        //     this.posts.push({
        //         label: String(_key).charAt(0).toUpperCase() + String(_key).slice(1),
        //         key: String(_key).toLowerCase(),
        //         posts: _posts
        //     })
        // }

        // let _featuredPosts = []

        // if (this.project?.featured?.projects?.length > 0) {
        //   for (let project of this.project.featured.projects) {
                  
        //           _featuredPosts.push({
        //               id: project.project_id,
        //               title: project.project_name,
        //               image: project.project_banner,
        //               logo: project.project_logo,
        //               description: project.project_description,
        //               ath: project.project_ath,
        //               tiers: project.project_tier_allocation,
        //               type: "project",
        //           })
        //       }
        // }

        // let _recentPosts = []



        // if (this.project?.recent?.projects?.length > 0) {

        //   for (let project of this.project.recent.projects) {
                    
        //             _recentPosts.push({
        //                 id: project.project_id,
        //                 title: project.project_name,
        //                 image: project.project_banner,
        //                 logo: project.project_logo,
        //                 description: project.project_description,
        //                 ath: project.project_ath,
        //                 tiers: project.project_tier_allocation,
        //                 type: "project",
        //             })
        //         } 
        // }

      //   this.posts.push({
      //       label: "Recent",
      //       key: "recent",
      //       posts: _recentPosts
      //   })

      //   this.posts.push({
      //     label: "Featured",
      //     key: "featured",
      //     posts: _featuredPosts
      // })

        // this.posts.push({
        //     label: "Completed",
        //     key: "completed",
        //     posts: _completedPosts
        // })

        this.update({
            posts: this.posts,
            // projects: this.project,
        })

    }

    refresh = async () => {

      this.posts = []
      this.project = {}
      this.announcements = []

      await this.render()
    }

}

export {
    HomeManager
}