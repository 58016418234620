import revive_payload_client_sBun8eKgVt from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_floating-vue@5.2.2_qrcode@1.5.3_rollup@2.79.1_sass@1.74.1_ty_ezukaqqlazirmkxvsg2mqowxki/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_a7EX2GkOuU from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_floating-vue@5.2.2_qrcode@1.5.3_rollup@2.79.1_sass@1.74.1_ty_ezukaqqlazirmkxvsg2mqowxki/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_3GOkAA6ko5 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_floating-vue@5.2.2_qrcode@1.5.3_rollup@2.79.1_sass@1.74.1_ty_ezukaqqlazirmkxvsg2mqowxki/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_krpiKW4vav from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_floating-vue@5.2.2_qrcode@1.5.3_rollup@2.79.1_sass@1.74.1_ty_ezukaqqlazirmkxvsg2mqowxki/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_1DA9kLzJsI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_floating-vue@5.2.2_qrcode@1.5.3_rollup@2.79.1_sass@1.74.1_ty_ezukaqqlazirmkxvsg2mqowxki/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_uNDrs4QXuL from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@2.79.1_typescript@5.4.4_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_gM3xGwvUwI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_floating-vue@5.2.2_qrcode@1.5.3_rollup@2.79.1_sass@1.74.1_ty_ezukaqqlazirmkxvsg2mqowxki/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_BKrVCP2DMh from "/opt/buildhome/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_rollup@2.79.1_vite@5.2.8_workbox-build@7.0.0_workbox-window@7.0.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Si4ftNGIbV from "/opt/buildhome/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_rollup@2.79.1_vite@5.2.8_workbox-build@7.0.0_workbox-window@7.0.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import nuxt_plugin_shJBUtP2Oe from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-delay-hydration@1.3.3_rollup@2.79.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import slideovers_pxEXxupDln from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.15.1_nuxt@3.11.2_qrcode@1.5.3_rollup@2.79.1_vite@5.2.8_vue@3.4.21/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_PLYyIL7aRb from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.15.1_nuxt@3.11.2_qrcode@1.5.3_rollup@2.79.1_vite@5.2.8_vue@3.4.21/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_qlRzFMMWgu from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.15.1_nuxt@3.11.2_qrcode@1.5.3_rollup@2.79.1_vite@5.2.8_vue@3.4.21/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_wWDl108MzV from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.3.3_rollup@2.79.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import floating_vue_EIcJ7xiw0h from "/opt/buildhome/repo/.nuxt/floating-vue.mjs";
import plugin_client_9AvREs3CQB from "/opt/buildhome/repo/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.3_rollup@2.79.1_vue@3.4.21/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.mjs";
import plugin_wofILYXtuU from "/opt/buildhome/repo/node_modules/.pnpm/@hypernym+nuxt-anime@2.1.1/node_modules/@hypernym/nuxt-anime/dist/runtime/plugin.mjs";
import chunk_reload_client_4WgU5vpTyK from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.0_floating-vue@5.2.2_qrcode@1.5.3_rollup@2.79.1_sass@1.74.1_ty_ezukaqqlazirmkxvsg2mqowxki/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_ZYZDYNylra from "/opt/buildhome/repo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_pinia@2.1.7_rollup@2.79.1/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import calendar_client_YJGsTbn2VI from "/opt/buildhome/repo/plugins/calendar.client.js";
import content_GArnI6Y6ho from "/opt/buildhome/repo/plugins/content.js";
import tracker_client_GsGCjPbvL8 from "/opt/buildhome/repo/plugins/tracker.client.js";
import vconsole_client_Zu3Hk7iamm from "/opt/buildhome/repo/plugins/vconsole.client.js";
import wallet_client_dYVbaY1ZcL from "/opt/buildhome/repo/plugins/wallet.client.js";
export default [
  revive_payload_client_sBun8eKgVt,
  unhead_a7EX2GkOuU,
  router_3GOkAA6ko5,
  payload_client_krpiKW4vav,
  check_outdated_build_client_1DA9kLzJsI,
  plugin_vue3_uNDrs4QXuL,
  components_plugin_KR1HBZs4kY,
  prefetch_client_gM3xGwvUwI,
  pwa_icons_BKrVCP2DMh,
  pwa_client_Si4ftNGIbV,
  nuxt_plugin_shJBUtP2Oe,
  slideovers_pxEXxupDln,
  modals_PLYyIL7aRb,
  colors_qlRzFMMWgu,
  plugin_client_wWDl108MzV,
  floating_vue_EIcJ7xiw0h,
  plugin_client_9AvREs3CQB,
  plugin_wofILYXtuU,
  chunk_reload_client_4WgU5vpTyK,
  plugin_ZYZDYNylra,
  calendar_client_YJGsTbn2VI,
  content_GArnI6Y6ho,
  tracker_client_GsGCjPbvL8,
  vconsole_client_Zu3Hk7iamm,
  wallet_client_dYVbaY1ZcL
]